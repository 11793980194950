// src/components/VirtualTable.js
import React from 'react';
import { TableRow, TableCell } from '@mui/material';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { styled } from '@mui/material/styles';

const StyledTableRow = styled(TableRow)(({ isEven }) => ({
  display: 'flex',
  width: '100%',
  backgroundColor: isEven ? '#f5f5f5' : 'white', // Light grey for even rows, white for odd rows
  '&:hover': {
    backgroundColor: '#eeeef0', // Optional: adds hover effect
  },
}));

const StyledTableCell = styled(TableCell)({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
});

const VirtualTable = ({ data }) => {
  const Row = ({ index, style }) => {
    const row = data[index];
    const isEven = index % 2 === 0;

    return (
      <StyledTableRow style={style} isEven={isEven}>
        <StyledTableCell>
          {row.type === 'ipv4' ? row.ip_prefix : row.ipv6_prefix}
        </StyledTableCell>
        <StyledTableCell>{row.region}</StyledTableCell>
        <StyledTableCell>{row.service}</StyledTableCell>
        <StyledTableCell>{row.type.toUpperCase()}</StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <AutoSizer>
      {({ height, width }) => (
        <FixedSizeList
          height={height}
          width={width}
          itemCount={data.length}
          itemSize={48}
        >
          {Row}
        </FixedSizeList>
      )}
    </AutoSizer>
  );
};

export default VirtualTable;
