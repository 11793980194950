import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Global, css } from '@emotion/react';

// Global styles
const globalStyles = css`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  @media (prefers-reduced-motion: reduce) {
    * {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }

  :focus-visible {
    outline: 3px solid #007bff;
    outline-offset: 2px;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }

  th {
    text-align: left;
  }

  label {
    display: block;
    margin-bottom: 0.5rem;
  }

  .error-message {
    background-color: #ff5252;
    color: white;
    padding: 1rem;
    border-radius: 4px;
    margin-bottom: 1rem;
  }

  .results-info {
    margin-bottom: 1rem;
    color: #666;
  }

  .no-more-results {
    text-align: center;
    padding: 2rem;
    color: #666;
  }

  @media (max-width: 768px) {
    .filter-group {
      flex-direction: column;
    }

    table {
      display: block;
      overflow-x: auto;
      white-space: nowrap;
    }
  }
`;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Global styles={globalStyles} />
    <App />
  </React.StrictMode>
);
