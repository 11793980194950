// src/components/FilterSection.js
import React, { useState } from 'react';
import { TextField, Box, Autocomplete } from '@mui/material';
import { styled } from '@mui/material/styles';

const FilterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  flexWrap: 'wrap',
  justifyContent: 'center',
  marginBottom: theme.spacing(2),
}));

// Helper functions for validation
const isValidIPv4Segment = (segment) => {
  if (segment === '') return true;
  const num = parseInt(segment);
  return !isNaN(num) && num >= 0 && num <= 255 && segment.match(/^\d{1,3}$/);
};

const isValidIPv6Segment = (segment) => {
  if (segment === '') return true;
  return segment.match(/^[0-9A-Fa-f]{0,4}$/);
};

const isValidPrefix = (prefix) => {
  if (prefix === '') return true;
  const num = parseInt(prefix);
  return !isNaN(num) && num >= 0 && num <= 128;
};

const validateIPInput = (value) => {
  if (value === '') return true;

  // Handle CIDR notation
  const [ip, prefix] = value.split('/');
  if (prefix && !isValidPrefix(prefix)) return false;

  // IPv4 validation
  if (ip.includes('.')) {
    const segments = ip.split('.');
    if (segments.length > 4) return false;
    return segments.every(isValidIPv4Segment);
  }

  // IPv6 validation
  if (ip.includes(':')) {
    const segments = ip.split(':');
    if (segments.length > 8) return false;
    return segments.every(isValidIPv6Segment);
  }

  return false;
};

const FilterSection = ({ filters, handleFilterChange, availableFilters }) => {
  const [ipError, setIpError] = useState(false);
  const maxLength = 43; // Maximum length for IPv6 with CIDR notation (39 + 4)

  const handleIpPrefixChange = (e) => {
    const value = e.target.value;
    const isValid = validateIPInput(value);
    setIpError(!isValid);
    handleFilterChange('ip_prefix', value);
  };

  return (
    <FilterContainer>
      <Autocomplete
        options={availableFilters.regions || []}
        value={filters.region}
        onChange={(event, newValue) => handleFilterChange('region', newValue || '')}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Filter by Region"
            variant="outlined"
            size="small"
            sx={{ minWidth: 200 }}
          />
        )}
      />
      
      <Autocomplete
        options={availableFilters.services || []}
        value={filters.service}
        onChange={(event, newValue) => handleFilterChange('service', newValue || '')}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Filter by Service"
            variant="outlined"
            size="small"
            sx={{ minWidth: 200 }}
          />
        )}
      />

      <TextField
        label="Filter by IP Prefix"
        value={filters.ip_prefix}
        onChange={handleIpPrefixChange}
        variant="outlined"
        size="small"
        sx={{ minWidth: 200 }}
        error={ipError}
        helperText={ipError ? "Please enter a valid IP address or prefix" : ""}
        inputProps={{
          maxLength: maxLength,
        }}
      />
    </FilterContainer>
  );
};

export default FilterSection;
