// src/App.js
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Button,
  Box,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import FilterSection from './components/FilterSection';
import VirtualTable from './components/VirtualTable';
import SortIcon from '@mui/icons-material/Sort';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

// Styled Components
// Styled Components
const HeaderContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '8px',
  cursor: 'pointer',
  fontWeight: 700,
  fontSize: '1.1rem',
});


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#1976d2',
    color: theme.palette.common.white,
    position: 'sticky',
    top: 0,
    zIndex: 2,
    fontWeight: 700,
    fontSize: '1.1rem',
  },
  flex: 1,
  display: 'flex',
  alignItems: 'center',
}));


const StickyHeader = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  backgroundColor: theme.palette.background.paper,
  zIndex: 3,
  padding: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const TableHeader = styled(TableRow)({
  display: 'flex',
  width: '100%',
});

const FilterControls = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
  marginTop: theme.spacing(2),
}));

const CounterText = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  margin: theme.spacing(2, 0),
  fontWeight: 500,
}));

function App() {
  const [ranges, setRanges] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [filters, setFilters] = useState({
    region: '',
    service: '',
    ip_prefix: ''
  });
  const [ipVersions, setIpVersions] = useState({
    ipv4: true,
    ipv6: true
  });
  const [availableFilters, setAvailableFilters] = useState({
    regions: [],
    services: []
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://ip-ranges.amazonaws.com/ip-ranges.json');
        const allRanges = [
          ...response.data.prefixes.map(prefix => ({ ...prefix, type: 'ipv4' })),
          ...response.data.ipv6_prefixes.map(prefix => ({ ...prefix, type: 'ipv6' }))
        ];
        setRanges(allRanges);
        
        const newFilters = {
          regions: [...new Set(allRanges.map(range => range.region))].sort(),
          services: [...new Set(allRanges.map(range => range.service))].sort()
        };
        setAvailableFilters(newFilters);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch IP ranges');
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const filteredAndSortedRanges = useCallback(() => {
    return ranges
      .filter(range => {
        const matchesIpVersion = 
          (range.type === 'ipv4' && ipVersions.ipv4) ||
          (range.type === 'ipv6' && ipVersions.ipv6);
        
        const matchesFilters =
          (!filters.region || range.region.toLowerCase().includes(filters.region.toLowerCase())) &&
          (!filters.service || range.service.toLowerCase().includes(filters.service.toLowerCase())) &&
          (!filters.ip_prefix || (range.type === 'ipv4' ? range.ip_prefix : range.ipv6_prefix).includes(filters.ip_prefix));

        return matchesIpVersion && matchesFilters;
      })
      .sort((a, b) => {
        if (!sortConfig.key) return 0;
        
        const aValue = sortConfig.key === 'ip_prefix' 
          ? (a.type === 'ipv4' ? a.ip_prefix : a.ipv6_prefix)
          : sortConfig.key === 'type'
          ? a.type.toUpperCase()
          : a[sortConfig.key];
        const bValue = sortConfig.key === 'ip_prefix'
          ? (b.type === 'ipv4' ? b.ip_prefix : b.ipv6_prefix)
          : sortConfig.key === 'type'
          ? b.type.toUpperCase()
          : b[sortConfig.key];

        if (aValue < bValue) return sortConfig.direction === 'ascending' ? -1 : 1;
        if (aValue > bValue) return sortConfig.direction === 'ascending' ? 1 : -1;
        return 0;
      });
  }, [ranges, filters, ipVersions, sortConfig]);

  const handleFilterChange = (filterType, value) => {
    setFilters(prev => ({
      ...prev,
      [filterType]: value
    }));
  };

  const resetFilters = () => {
    setFilters({
      region: '',
      service: '',
      ip_prefix: ''
    });
    setIpVersions({
      ipv4: true,
      ipv6: true
    });
  };

  const handleSort = (key) => {
    setSortConfig(prevConfig => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'ascending' 
        ? 'descending' 
        : 'ascending'
    }));
  };

  const getSortIcon = (column) => {
    if (sortConfig.key !== column) {
      return <SortIcon sx={{ opacity: 0.5 }} />;
    }
    return sortConfig.direction === 'ascending' ? 
      <ArrowUpwardIcon /> : 
      <ArrowDownwardIcon />;
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  const filteredRanges = filteredAndSortedRanges();

  return (
    <Box sx={{ padding: 2, height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <StickyHeader>
        <Typography variant="h4" gutterBottom align="center">
          AWS IP Ranges
        </Typography>
        
        <FilterSection 
          filters={filters}
          handleFilterChange={handleFilterChange}
          availableFilters={availableFilters}
        />

        <FilterControls>
          <FormGroup row sx={{ justifyContent: 'center' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={ipVersions.ipv4}
                  onChange={(e) => setIpVersions(prev => ({ ...prev, ipv4: e.target.checked }))}
                />
              }
              label="IPv4"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={ipVersions.ipv6}
                  onChange={(e) => setIpVersions(prev => ({ ...prev, ipv6: e.target.checked }))}
                />
              }
              label="IPv6"
            />
          </FormGroup>

          <Button onClick={resetFilters} variant="outlined">
            Reset Filters
          </Button>

          <CounterText>
            Showing {filteredRanges.length} entries
          </CounterText>
        </FilterControls>
      </StickyHeader>

      <TableContainer 
        component={Paper} 
        sx={{ 
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden'
        }}
      >
        <Table>
          <TableHead>
            <TableHeader>
              <StyledTableCell>
                <HeaderContent onClick={() => handleSort('ip_prefix')}>
                  IP Prefix {getSortIcon('ip_prefix')}
                </HeaderContent>
              </StyledTableCell>
              <StyledTableCell>
                <HeaderContent onClick={() => handleSort('region')}>
                  Region {getSortIcon('region')}
                </HeaderContent>
              </StyledTableCell>
              <StyledTableCell>
                <HeaderContent onClick={() => handleSort('service')}>
                  Service {getSortIcon('service')}
                </HeaderContent>
              </StyledTableCell>
              <StyledTableCell>
                <HeaderContent onClick={() => handleSort('type')}>
                  Type {getSortIcon('type')}
                </HeaderContent>
              </StyledTableCell>
            </TableHeader>
          </TableHead>
        </Table>
        <Box sx={{ flex: 1 }}>
          <VirtualTable data={filteredRanges} />
        </Box>
      </TableContainer>
    </Box>
  );
}

export default App;
